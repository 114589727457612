import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import TvIcon from '@mui/icons-material/Tv';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link } from 'react-router-dom';
import _channels from '../../services/channelService';
import _assets from '../../services/assetService';
import _follows from '../../services/followService';
import _watch from '../../services/watchService';
import VisibilityIcon from '@mui/icons-material/Visibility';
import _channelViewers from '../../services/channelViewerService';
import CircleIcon from '@mui/icons-material/Circle';
import { updateUserFollows } from '../../redux/actions/userFollowActions';
import { updateFollows } from '../../utility/utility';

class LiveCard extends React.Component {
    state = {
        viewerCount: 0,
    }
    toggleFollow = () => {
        const _this = this;
        if (_this.props.follow) {
            _follows.deleteFollow(_this.props.follow.followId).then(_ => {
                updateFollows(this.props.updateUserFollows);
            });
        }
        else {
            _follows.createFollow(_this.props.channel.channelId).then(_ => {
                updateFollows(this.props.updateUserFollows);
            });
        }
    }
    componentDidMount() {
        _channelViewers.getChannelViewers(this.props.channel.channelId).then(viewers => {
            this.setState({ viewerCount: viewers })
        });
    }

    render() {
        const _this = this;
        const channel = _this.props.channel;
        const follow = _this.props.follow;
        const user = _this.props.user;
        return (
            <Grid item p={1}>
                <Card sx={{ maxWidth: 400 }}>
                    <Link to={`/channel/${channel.channelId}`}>
                        <CardMedia
                            style={{ borderRadius: "5%", backgroundColor: "black", width: "400px", height: "225px" }}
                            component="img"
                            width="400"
                            height="400"
                            src={_watch.getTumbnailUrl(channel.channelId)}
                            alt={channel.channelName + " image"}
                        />
                    </Link>
                    <CardContent>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography gutterBottom variant="h5" component="div">
                                    {channel.channelName}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {channel.isLive ? <span style={{ float: 'right' }}><CircleIcon fontSize="small" style={{ color: "red", animation: "fade 2s linear infinite" }} />&nbsp;Live</span> : ""}
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                {
                                    user ? (
                                        <Button onClick={this.toggleFollow} variant={follow ? 'contained' : 'outlined'} color="secondary" size="small">Follow&nbsp;<FavoriteIcon sx={{ color: follow ? 'lightcoral' : '' }} /></Button>
                                    )
                                        : ''
                                }
                                <Link to={`/channel/${channel.channelId}`} style={{ textDecoration: 'none' }}><Button variant='outlined' color="secondary" size="small">Channel&nbsp;<TvIcon /></Button></Link>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center" style={{ color: "grey" }}>
                                    <Grid item>
                                        <VisibilityIcon />
                                    </Grid>
                                    <Grid item>
                                        &nbsp;&nbsp;{this.state.viewerCount} watching
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </CardActions>
                </Card>
            </Grid>
        );
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = { updateUserFollows };

export default connect(mapState, mapDispatch)(LiveCard);