import _api from "./serviceClient";
import { API_URL } from "./config";

const _events = {
    getEvent: (id) => {
        return _api.get(API_URL, `/Event/GetEvent?eventId=${id}`);
    },
    getEventsByChannelId: (id) => {
        return _api.get(API_URL, `/Event/GetEventsByChannelId?channelId=${id}`);
    },
    updateEvent: (event) => {
        let request = {
            ...event,
        }
        return _api.patch(API_URL, `/Event/UpdateEvent`, request);
    },
    deleteEvent: (id) => {
        return _api.post(API_URL, `/Event/DeleteEvent`, id);
    },
}

export default _events;