import _api from "./serviceClient";
import { API_URL } from "./config";

const _channels = {
    getChannelById: (id) => {
        return _api.get(API_URL, `/Channel/GetChannelById?channelId=${id}`);
    },
    getChannelByName: (name) => {
        return _api.get(API_URL, `/Channel/GetChannelsByName?name=${name}`);
    },
    getUserChannel: () => {
        return _api.get(API_URL, `/Channel/GetUserChannel`);
    },
    isChannelLive: (channelId) => {
        return _api.get(API_URL, `/Channel/IsChannelLive?channelId=${channelId}`);
    },
    getChannelPictureByChannelId: (channelId) => {
        return _api.getUrl(API_URL, `/Channel/GetChannelPictureByChannelId?channelId=${channelId}`);
    },
    updateChannel: (channel) => {
        let request = {
            ...channel,
        }
        return _api.patch(API_URL, `/Channel/UpdateChannel`, request);
    },
    getStreamKey: () => {
        return _api.get(API_URL, `/Channel/GetStreamKey`);
    },
    setStreamKey: () => {
        return _api.post(API_URL, `/Channel/SetStreamKey`);
    },
}

export default _channels;