import { Avatar, Box, Divider, Grid, IconButton, InputBase, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import _chat from '../../services/chatService';
import _users from '../../services/userService';
import { isMobile } from '../../utility/utility';
import ModeCommentIcon from '@mui/icons-material/ModeComment';

class ChatInterface extends React.Component {
    state = {
        messages: [],
        draft: "",
    }
    getAllMessages = (messages) => {
        this.setState({ messages: messages });
        this.scrollToBottom();
    }
    getMessage = (message) => {
        this.setState({ messages: [...this.state.messages, message] });
        this.scrollToBottom();
    }
    componentDidMount() {
        const _this = this;
        _chat.start(_this.getAllMessages, _this.getMessage).then(_ => {
            _chat.joinGroup(this.props.channelId);
            _chat.getAllMessages(this.props.channelId);
        })
    }
    componentWillUnmount() {
        _chat.leaveGroup(this.props.channelId).then(_ => {
            _chat.stop();
        });
    }
    editMessage = (event) => {
        const _this = this;
        _this.setState({ draft: event.target.value });
    }
    keyPress = (event) => {
        const _this = this;
        if (event.keyCode == 13) {
            _this.sendMessage();
        }
    }
    sendMessage = () => {
        const _this = this;
        if (this.state.draft) {
            _chat.send(this.props.channelId, {
                text: this.state.draft,
                userId: this.props.user.userId,
                userName: this.props.user.userName,
                sentDate: new Date(),
                groupName: this.props.channelId,
            })
        }

        _this.setState({ draft: "" });
    }

    scrollToBottom = () => {
        setTimeout(() => {
            var elem = document.getElementById('channel-chat-history');
            elem.scrollTop = elem.scrollHeight;
        }, 100);
    }

    render() {
        return (
            <Paper elevation={3} style={{ height: isMobile() ? 'auto' : '100%' }}>
                <Box sx={{ display: 'flex', flexDirection:'column', alignContent:"space-between" }} style={{ height: "100%" }}>
                    <Box p={1}>
                        <ModeCommentIcon />
                        <b style={{ margin: "20px" }}>Chat</b>
                        <Divider sx={{mt:2}} />
                    </Box>
                    <div id="channel-chat-history" className="scroller" style={{ maxHeight: isMobile() ? '400px' : 'none', overflow: "auto", flexGrow:1 }}>
                        <List >
                            {
                                this.state.messages.map(message => {
                                    return (
                                        <React.Fragment>
                                            <ListItem alignItems="flex-start">
                                                <ListItemAvatar>
                                                    <Avatar src={message.userId ? _users.getUserProfilePictureByUserIdUrl(message.userId) : ""} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={message.userName}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                            </Typography>
                                                            {message.text}
                                                            <br />
                                                            <Typography
                                                                sx={{ display: 'inline', color: 'gray', fontSize: '0.875rem' }} // Adjust color and font size as needed
                                                                component="span"
                                                                variant="body2"
                                                            >
                                                                {new Date(message.sentDate).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </List>
                    </div>
                    {this.props.user.userId &&
                        <div>
                            <Grid container >
                                <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%" }}>
                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Message"
                                        inputProps={{ 'aria-label': 'message' }}
                                        value={this.state.draft}
                                        onChange={this.editMessage}
                                        onKeyDown={this.keyPress}
                                    />
                                    <IconButton onClick={this.sendMessage} sx={{ p: '10px' }} aria-label="send">
                                        <SendIcon />
                                    </IconButton>
                                </Paper>
                            </Grid>
                        </div>
                    }
                </Box>

            </Paper>
        );
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(ChatInterface);