import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import TvIcon from '@mui/icons-material/Tv';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link } from 'react-router-dom';
import _channels from '../../services/channelService';
import _assets from '../../services/assetService';
import _follows from '../../services/followService';
import CircleIcon from '@mui/icons-material/Circle';
import { updateUserFollows } from '../../redux/actions/userFollowActions';
import { updateFollows } from '../../utility/utility';


class ChannelCard extends React.Component {
    toggleFollow = () => {
        const _this = this;
        if (_this.props.follow) {
            _follows.deleteFollow(_this.props.follow.followId).then(_ => {
                updateFollows(this.props.updateUserFollows);
            });
        }
        else {
            _follows.createFollow(_this.props.channel.channelId).then(_ => {
                updateFollows(this.props.updateUserFollows);
            });
        }
    }
    render() {
        const _this = this;
        const channel = _this.props.channel;
        const follow = _this.props.follow;
        const user = _this.props.user;
        return (
            <Grid item p={1}>
                <Card sx={{ maxWidth: 345 }}>
                    <Link to={`/channel/${channel.channelId}`} >
                        <CardMedia
                            style={{ borderRadius: "50%", backgroundColor: "black", width: "300px", height: "300px" }}
                            component="img"
                            width="400"
                            height="400"
                            src={channel.channelPictureId ? _assets.getFileUrl(channel.channelPictureId) : "/content/images/logo-glow.png"}
                            alt={channel.channelName + " image"}
                        />
                    </Link>
                    <CardContent>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography gutterBottom variant="h5" component="div">
                                    {channel.channelName}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {channel.isLive ? <span style={{ float: 'right' }}><CircleIcon fontSize="small" style={{ color: "red", animation: "fade 2s linear infinite" }} />&nbsp;Live</span> : ""}
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        {
                            user ? (
                                <Button onClick={this.toggleFollow} variant={follow ? 'contained' : 'outlined'} color="secondary" size="small">Follow&nbsp;<FavoriteIcon sx={{ color: follow ? 'lightcoral' : '' }} /></Button>
                            )
                                : ''
                        }
                        <Link to={`/channel/${channel.channelId}`} style={{ textDecoration: 'none' }}><Button variant='outlined' color="secondary" size="small">Channel&nbsp;<TvIcon /></Button></Link>
                    </CardActions>
                </Card>
            </Grid>
        );
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = { updateUserFollows };

export default connect(mapState, mapDispatch)(ChannelCard);